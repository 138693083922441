import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACTUAL_YEAR } from "../../../../Config/config";
import { regexRemoveSpaces } from "../../../../Config/utils";
import {
  prepareDataDB,
  updateDayBorn,
  updateMonthBorn,
  updateNextArrowState,
  updateYearBorn,
} from "../../../../Store/store";
import "./Birthdate.scss";

function updateArrow(dispatch, dayValid, monthValid, yearValid) {
  if (dayValid && monthValid && yearValid) {
    dispatch(updateNextArrowState("activate"));
  } else {
    dispatch(updateNextArrowState("disable"));
  }
}

function checkDataDay(parsedValue) {
  return parsedValue > 0 && parsedValue <= 31;
}

function handleChangesDay(
  event,
  dispatch,
  monthValid,
  yearValid,
  updateDayValid,
  mySessionId,
  myTimestamp
) {
  const parsedValue = parseInt(regexRemoveSpaces(event.target.value));

  dispatch(updateDayBorn(parsedValue));
  dispatch(
    prepareDataDB({
      sessionId: mySessionId,
      timestamp: myTimestamp,
      nameDb: "dayBorn",
      content: parsedValue,
    })
  );

  if (checkDataDay(parsedValue)) {
    updateDayValid(true);
    updateArrow(dispatch, true, monthValid, yearValid);
  } else {
    updateDayValid(false);
    updateArrow(dispatch, false, monthValid, yearValid);
  }
}

function checkDataMonth(parsedValue) {
  return parsedValue > 0 && parsedValue <= 12;
}

function handleChangesMonth(
  event,
  dispatch,
  dayValid,
  yearValid,
  updateMonthValid,
  mySessionId,
  myTimestamp
) {
  const parsedValue = parseInt(regexRemoveSpaces(event.target.value));

  dispatch(updateMonthBorn(parsedValue));
  dispatch(
    prepareDataDB({
      sessionId: mySessionId,
      timestamp: myTimestamp,
      nameDb: "monthBorn",
      content: parsedValue,
    })
  );

  if (checkDataMonth(parsedValue)) {
    updateMonthValid(true);
    updateArrow(dispatch, dayValid, true, yearValid);
  } else {
    updateMonthValid(false);
    updateArrow(dispatch, dayValid, false, yearValid);
  }
}

function checkDataYear(parsedValue) {
  return parsedValue > 1920 && parsedValue <= ACTUAL_YEAR;
}

function handleChangesYear(
  event,
  dispatch,
  dayValid,
  monthValid,
  updateYearValid,
  mySessionId,
  myTimestamp
) {
  const parsedValue = parseInt(regexRemoveSpaces(event.target.value));

  dispatch(updateYearBorn(parsedValue));
  dispatch(
    prepareDataDB({
      sessionId: mySessionId,
      timestamp: myTimestamp,
      nameDb: "yearBorn",
      content: parsedValue,
    })
  );

  if (checkDataYear(parsedValue)) {
    updateYearValid(true);
    updateArrow(dispatch, dayValid, monthValid, true);
  } else {
    updateYearValid(false);
    updateArrow(dispatch, dayValid, monthValid, false);
  }
}

export default function Birthdate() {
  let mySessionId = useSelector((state) => state.backend.sessionId);
  let myTimestamp = useSelector((state) => state.backend.timestamp);
  let dayBorn = useSelector((state) => state.userInput.dayBorn);
  let monthBorn = useSelector((state) => state.userInput.monthBorn);
  let yearBorn = useSelector((state) => state.userInput.yearBorn);
  let [dayValid, updateDayValid] = useState(checkDataDay(dayBorn));
  let [monthValid, updateMonthValid] = useState(checkDataMonth(monthBorn));
  let [yearValid, updateYearValid] = useState(checkDataYear(yearBorn));
  const dispatch = useDispatch();
  setTimeout(() => {
    updateArrow(dispatch, dayValid, monthValid, yearValid);
  }, 200);

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      document.querySelector(".right-arrow.arrow button").click();
    }
  }

  return (
    <div className="birthdate-container">
      <div className="day-container">
        <label htmlFor="birthdate-day">Jour :</label>
        <input
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          value={dayBorn}
          onChange={(event) => {
            handleChangesDay(
              event,
              dispatch,
              monthValid,
              yearValid,
              updateDayValid,
              mySessionId,
              myTimestamp
            );
          }}
          placeholder="Ex: 7"
          type="numerous"
          className="birthdate-day"
          name="birthdate-day"
        />
      </div>

      <div className="month-container">
        <label htmlFor="birthdate-month">Mois :</label>
        <input
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          placeholder="Ex: 8"
          value={monthBorn}
          onChange={(event) => {
            handleChangesMonth(
              event,
              dispatch,
              dayValid,
              yearValid,
              updateMonthValid,
              mySessionId,
              myTimestamp
            );
          }}
          type="numerous"
          className="birthdate-month"
          name="birthdate-month"
        />
      </div>

      <div className="year-container">
        <label htmlFor="birthdate-year">Annee :</label>
        <input
          onKeyDown={(event) => {
            handleKeyDown(event);
          }}
          placeholder="Ex: 1985"
          value={yearBorn}
          onChange={(event) => {
            handleChangesYear(
              event,
              dispatch,
              dayValid,
              monthValid,
              updateYearValid,
              mySessionId,
              myTimestamp
            );
          }}
          type="numerous"
          className="birthdate-year"
          name="birthdate-year"
        />
      </div>
    </div>
  );
}
