import React from "react";
import Couvertures from "../Pages/Couvertures";
import DateNaissance from "../Pages/DateNaissance";
import DureeEmprunt from "../Pages/DureeEmprunt";
import Fumeur from "../Pages/Fumeur";
import MontantEmprunt from "../Pages/MontantEmprunt";
import Quotite from "../Pages/Quotite";
import StatutProfessionnel from "../Pages/StatutProfessionnel";
import TypePret from "../Pages/TypePret";
import LastName from "../Pages/LastName";
import Email from "../Pages/Email";
import Phone from "../Pages/Phone";
import Results from "../Pages/Results";

export const simulateur_name = "Assurance emprunteur";
export const categories = ["Prêt", "Postulant", "Informations"];
// eslint-disable-next-line react/jsx-key
export const defaultAllPages = [<TypePret />, <Couvertures/>, <MontantEmprunt/>, <DureeEmprunt/>, <Quotite/>, <StatutProfessionnel/>, <Fumeur/>, <DateNaissance/>, <LastName/>, <Email/>, <Phone />, <Results /> ];
export const ACTUAL_YEAR = 2022;
export const backendUrl = "https://backend.lexem.io/";