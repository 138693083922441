/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateStatutProfessionnel } from "../Store/store";

export default function StatutProfessionnel(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Quel est votre statut professionnel ?"
        subtitle="Pour affiner notre calcul"
        pageType="picklist"
        values={[
          "Activité professionnelle rémunérée (Salarié(e) / TNS)",
          "Profession médicales ou vétérinaire",
          "Professions paramédicales",
          "Inactif"
        ]}
        reduxStateName="statutProfessionnel"
        reduxAction={updateStatutProfessionnel}
        myNameDb="statutProfessionnel"
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
