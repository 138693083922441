/* eslint-disable react/prop-types */
import React from "react";
import "./Optin.scss";

export default function Optin(props) {
  return (
    <div className="optin">
      <label className="switch">
        <input
          onChange={(event) => {
            props.onChange(event);
          }}
          type="checkbox"
          checked={props.value == "true"}
          className="optin-checkbox"
        />
        <span className="slider"></span>
      </label>

      <p className="text">{props.text}</p>
    </div>
  );
}
