/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Header from "../Components/Simulation/Header";
import "./Results.scss";
import person from "../Pictures/person.jpg";
import { FaInfoCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import Cards from "../Components/Result/Cards";
import ModalPhone from "../Components/Result/utils/ModalPhone";
import { backendUrl } from "../Config/config";
import { prepareDataDB, sendDataDb, updatePhoneChecked } from "../Store/store";
import Redirect from "../Components/Redirect";
import Loader from "../Components/Result/Loader";
import { putSpaces } from "../Config/utils";

function sendToken(myPhone) {
  fetch(backendUrl + "simulations/emprunteur_ibani/2fa/send/index.php", {
    method: "POST",
    body: JSON.stringify({ phone: myPhone }),
    credentials: "include",
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    });
}

function checkToken(
  myPhone,
  myToken,
  dispatch,
  myTimestamp,
  mySessionId,
  updateError,
  userData,
  totalCostInsurance,
  dataApi
) {
  fetch(backendUrl + "simulations/emprunteur_ibani/2fa/check/index.php", {
    method: "POST",
    body: JSON.stringify({ phone: myPhone, token: myToken }),
    credentials: "include",
    crossDomain: true,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.statut == "success") {
        dispatch(updatePhoneChecked("true"));
        dispatch(
          prepareDataDB({
            sessionId: mySessionId,
            timestamp: myTimestamp,
            nameDb: "phoneChecked",
            content: "true",
          })
        );
        dispatch(sendDataDb());

        // Si nous n'avons pas encore reçu le résultat de la part de l'API de CBP, on refait notre requête
        // et on envoie la data dans SF uniquement une fois le résultat reçu par l'API de CBP.

        if (totalCostInsurance === 0) {
          // On refait la requête à l'API de CBP
          fetch(backendUrl + "simulations/emprunteur_ibani/results/index.php", {
            method: "POST",
            body: JSON.stringify(dataApi),
            credentials: "include",
            crossDomain: true,
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.statut == "success") {
                userData.totalCostInsurance = data.coutTotalAssurance;
              } else {
                userData.totalCostInsurance = 0;
              }

              fetch(backendUrl + "simulations/emprunteur_ibani/sendData/index.php", {
                method: "POST",
                body: JSON.stringify(userData),
                credentials: "include",
                crossDomain: true,
              });
            });
        } else {
          userData.totalCoutAssurance = totalCostInsurance;
          fetch(backendUrl + "simulations/emprunteur_ibani/sendData/index.php", {
            method: "POST",
            body: JSON.stringify(userData),
            credentials: "include",
            crossDomain: true,
          });
        }
      } else {
        // Show error message
        updateError(true);
      }
    });
}

export default function Results(props) {
  let lastName = useSelector((state) => state.userInput.lastName);
  let phoneValidated = useSelector((state) => state.backend.phoneChecked);
  let phone = useSelector((state) => state.userInput.phone);
  let [totalCostInsurance, setTotalCostInsurrance] = useState(0);
  let statutProfessionnel = useSelector(
    (state) => state.userInput.statutProfessionnel
  );
  let couvertures = useSelector((state) => state.userInput.couvertures);
  let yearBorn = useSelector((state) => state.userInput.yearBorn);
  let monthBorn = useSelector((state) => state.userInput.monthBorn);
  let dayBorn = useSelector((state) => state.userInput.dayBorn);
  let fumeur = useSelector((state) => state.userInput.fumeur);
  let montantEmprunt = useSelector((state) => state.userInput.montantEmprunt);
  let dureeEmprunt = useSelector((state) => state.userInput.dureeEmprunt);
  let quotite = useSelector((state) => state.userInput.quotite);
  let typePret = useSelector((state) => state.userInput.typePret);
  let email = useSelector((state) => state.userInput.email);
  let [loader, setLoader] = useState("active");

  let dataApi = {
    statutProfessionnel,
    couvertures,
    yearBorn,
    monthBorn,
    dayBorn,
    fumeur,
    montantEmprunt,
    dureeEmprunt,
    quotite,
    typePret,
  };

  useEffect(() => {
    fetch(backendUrl + "simulations/emprunteur_ibani/results/index.php", {
      method: "POST",
      body: JSON.stringify(dataApi),
      credentials: "include",
      crossDomain: true,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statut == "success") {
          setLoader("disable");
          setTotalCostInsurrance(data.coutTotalAssurance);
        } else {
          window.location("https://lexem.io/");
        }
      });
  });

  const myUserData = {
    typePret,
    couvertures,
    montantEmprunt,
    dureeEmprunt,
    quotite,
    statutProfessionnel,
    fumeur,
    monthBorn,
    dayBorn,
    yearBorn,
    firstName: "M.",
    lastName,
    phone,
    email,
  };

  return (
    <>
      <Loader className={loader} />
      {phoneValidated === "false" ? (
        <ModalPhone
          phone={phone}
          sendSms={sendToken}
          validate={checkToken}
          userData={myUserData}
          totalCostInsurance={totalCostInsurance}
          dataApi={dataApi}
        />
      ) : (
        ""
      )}
      <Redirect pageIndex={props.pageIndex} />
      <div className="result-page">
        <Header showProgressBar={false} />
        <div className="content">
          <div className="flex-div">
            <img
              className="image-person"
              src={person}
              alt="Photo d'un visage"
            />
            <h2>Bienvenue M./Mme. {lastName} !</h2>
          </div>
          <p className="welcome-text">
            Félicitations ! 
            Vous avez entrepris des démarches pour <b>économiser sur votre assurance emprunteur</b> et nous pouvons vous aider pour ça. Notre algorithme a calculé <b>un nouveau tarif de votre assurance emprunteur</b> ! 
          </p>
          <div
            style={{
              width: "fit-content",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
            }}
          >
            <h1 className="title-simulation-results">
              Simulation de votre tarif assurance emprunteur avec Lexem
            </h1>
            <Cards
              numerous={putSpaces(parseInt(totalCostInsurance), 3)}
              className={"blue"}
              text={"Coût total estimé"}
            />
          </div>
          <p style={{fontSize: "18px", fontWeight: "600"}}>Soit en moyenne un  <span style={{color: "#015de6"}}>réduction</span> de <span style={{color: "#015de6"}}>31%</span> consultée chez nos clients.</p>

          <div className="we-will-call-you">
            <FaInfoCircle fontSize={"28px"} style={{ flexShrink: 0 }} />
            <p>
            Un(e) expert(e) Lexem vous contactera dans les 48h pour détailler les différentes offres sur votre contrat assurance emprunteur et répondre à vos questions. 
            </p>
          </div>

          <h3 className="steps-title">
            Les étapes pour réduire le tarif de votre assurance emprunteur avec Lexem
          </h3>

          <div className="steps-container">
            <div className="step">
              <div className="line1">
                <div className="icon-number">1</div>
                <p>Vous avez obtenu vos résultats de simulation</p>
              </div>
            </div>
            <div className="step colored">
              <div className="line1">
                <div className="icon-number">2</div>
                <p>
                  Validez les termes de votre contrat avec un conseiller Lexem
                </p>
              </div>
              <div className="line2">
              Un(e) expert(e) Lexem vous contactera dans 48h pour analyser votre situation gratuitement et répondre à vos questions
              </div>
            </div>
            <div className="step">
              <div className="line1">
                <div className="icon-number">3</div>
                <p>
                  Demandez à votre conseiller de s&apos;occuper de la résiliation de votre ancien contrat
                </p>
              </div>
              <div className="line2"></div>
            </div>
            <div className="step">
              <div className="line1">
                <div className="icon-number">4</div>
                <p>
                  Consultez l&apos;évolution de votre assurance emprunteur en ligne à tout moment !
                </p>
              </div>
              <div className="line2"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
