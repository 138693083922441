/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { InformationLeaveIbani } from "../Components/Simulation/information-leave-ibani/InformationLeaveIbani";
import { updateTypePret } from "../Store/store";

export default function TypePret(props) {
  return (
    <div className="page-container">
      <InformationLeaveIbani />
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />

      <Page
        title="Quel est le type de prêt ?"
        subtitle="Le type de prêt accordé par votre banque initialement"
        pageType="picklist"
        values={["Amortissable", "In Fine", "Prêt Relais"]}
        pageIndex={props.pageIndex}
        totalPages={props.TotalPages}
        reduxStateName="typePret"
        reduxAction={updateTypePret}
        myNameDb="typePret"
      />
    </div>
  );
}
