/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { updateDureeEmprunt } from "../Store/store";

function checkData(value) {
  const numerousValue = parseInt(regexRemoveSpaces(value));
  return numerousValue > 0 && numerousValue <= 300;
}

export default function DureeEmprunt(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title={
          <>
            Quelle est la durée de votre emprunt (
            <span style={{ color: "#015de6" }}>EN MOIS</span>) ?
          </>
        }
        subtitle="La durée restante de votre crédit ou la durée totale de votre nouvel emprunt"
        pageType="input"
        type="numerous"
        placeholder="Ex: 132"
        reduxAction={updateDureeEmprunt}
        reduxStateName="dureeEmprunt"
        checkData={checkData}
        dataType="money-integer"
        myNameDb="dureeEmprunt"
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
