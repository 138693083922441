/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { updateQuotite } from "../Store/store";

function checkData(value) {
  const numerousValue = parseInt(regexRemoveSpaces(value));

  return numerousValue > 0 && numerousValue <= 100;
}

export default function Quotite(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title={
          <>
            Quelle est <span style={{ color: "#015de6" }}>VOTRE</span> quotité
            d&lsquo;emprunt ? (en %)
          </>
        }
        subtitle="Quel est le taux de garantie requis (généralement 100%)"
        pageType="input"
        type="numerous"
        placeholder="Ex: 100"
        reduxAction={updateQuotite}
        reduxStateName="quotite"
        checkData={checkData}
        dataType="integer"
        myNameDb="quotite"
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
