import React from "react";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import "./main.scss";

function App(props) {
  return <Provider store={store}>{props.component}</Provider>;
}
export default App;
/* eslint react/prop-types: 0 */
