import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextPage, previousPage, sendDataDb } from "../Store/store";
import "./ArrowsPhone.scss";

function goNextPage(dispatch, mySessionId, myTimestamp) {
  dispatch(sendDataDb(null));
  dispatch(nextPage({ sessionId: mySessionId, timestamp: myTimestamp }));
}

function goPreviousPage(dispatch, mySessionId, myTimestamp) {
  dispatch(sendDataDb(null));
  dispatch(previousPage({ sessionId: mySessionId, timestamp: myTimestamp }));
}

export default function ArrowsPhone() {
  let nextArrowState = useSelector((state) => state.navigation.nextArrowState);
  let mySessionId = useSelector((state) => state.backend.sessionId);
  let myTimestamp = useSelector((state) => state.backend.timestamp);
  const dispatch = useDispatch();

  return (
    <div className="arrows-phone only-display-phone">
      <div
        className="left-arrow-phone arrow-phone"
        onClick={() => {
          goPreviousPage(dispatch, mySessionId, myTimestamp);
        }}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 12H5"
            stroke="#040405"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M12 19L5 12L12 5"
            stroke="#040405"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        Retour
      </div>
      <div
        onClick={() => {
          goNextPage(dispatch, mySessionId, myTimestamp);
        }}
        className={"right-arrow-phone arrow-phone " + nextArrowState}
      >
        Suivant
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#fff"
            d="M5 12H19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            stroke="#fff"
            d="M12 5L19 12L12 19"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      </div>
    </div>
  );
}
