import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextPage, sendDataDb } from "../../Store/store";
import "./Arrow.scss";

function goNextPage(dispatch, mySessionId, myTimestamp) {
  dispatch(sendDataDb(null));
  dispatch(nextPage({ sessionId: mySessionId, timestamp: myTimestamp }));
}

export default function RightArrow() {
  const dispatch = useDispatch();
  let nextArrowState = useSelector((state) => state.navigation.nextArrowState);
  let mySessionId = useSelector((state) => state.backend.sessionId);
  let myTimestamp = useSelector((state) => state.backend.timestamp);

  return (
    <div className="right-arrow arrow no-display-phone">
      <button
        className={nextArrowState}
        onClick={() => {
          goNextPage(dispatch, mySessionId, myTimestamp);
        }}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.125 10H16.875"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.25 4.375L16.875 10L11.25 15.625"
            stroke="white"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
