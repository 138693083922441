/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";

export default function DateNaissance(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title={"Quelle est votre date de naissance ?"}
        subtitle="Pour établir la simulation"
        pageType="birthdate"
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
