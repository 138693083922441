/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { regexRemoveSpaces } from "../Config/utils";
import { updateMontantEmprunt } from "../Store/store";

function checkData(value) {
  const numerousValue = parseInt(regexRemoveSpaces(value));

  return numerousValue > 1000 && numerousValue < 10000000;
}

export default function MontantEmprunt(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Quelle est la somme empruntée ?"
        subtitle="Le montant de votre emprunt ou le capital restant dû"
        pageType="input"
        type="numerous"
        placeholder="Ex: 250 000"
        reduxAction={updateMontantEmprunt}
        reduxStateName="montantEmprunt"
        checkData={checkData}
        dataType="money-integer"
        myNameDb="montantEmprunt"
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
