import { configureStore, createSlice } from "@reduxjs/toolkit";
import { backendUrl, defaultAllPages } from "../Config/config";

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    categories: ["Prêt", "Postulant", "Informations"],
    pagePerCategoriesList: [
      [1, 2, 3, 4, 5],
      [6, 7, 8],
      [9, 10, 11],
    ],
    actualPageIndex: 1,
    tempPageIndex: 1,
    nextArrowState: "disable",
  },
  reducers: {
    nextPage: (state, action) => {
      if (
        state.actualPageIndex !== defaultAllPages.length &&
        state.nextArrowState === "activate"
      ) {
        state.nextArrowState = "disable";
        state.actualPageIndex++;

        const dbData = {
          sessionId: action.payload.sessionId,
          timestamp: action.payload.timestamp,
          actualPage: state.actualPageIndex,
        };

        fetch(backendUrl + "simulations/emprunteur_ibani/update/index.php", {
          method: "POST",
          body: JSON.stringify(dbData),
          credentials: "include",
          crossDomain: true,
        });
      }
    },
    previousPage: (state, action) => {
      if (state.actualPageIndex !== 1) {
        state.nextArrowState = "activate";
        state.actualPageIndex--;

        const dbData = {
          sessionId: action.payload.sessionId,
          timestamp: action.payload.timestamp,
          actualPage: state.actualPageIndex,
        };

        fetch(backendUrl + "simulations/emprunteur_ibani/update/index.php", {
          method: "POST",
          body: JSON.stringify(dbData),
          credentials: "include",
          crossDomain: true,
        });
      }
    },
    updateActualPageIndex: (state, action) => {
      state.actualPageIndex = action.payload;
    },
    updateNextArrowState: (state, action) => {
      state.nextArrowState = action.payload;
    },
    updateTempPageIndex: (state, action) => {
      state.tempPageIndex = action.payload;
    },
    putTempPageIndex: (state) => {
      state.actualPageIndex = state.tempPageIndex;
    },
  },
});

const userInputSlice = createSlice({
  name: "userInput",
  initialState: {
    montantEmprunt: "",
    dureeEmprunt: "",
    quotite: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dayBorn: "",
    monthBorn: "",
    yearBorn: "",
    typePret: "",
    couvertures: "",
    statutProfessionnel: "",
    fumeur: "",
    optin: "false",
    dbData: [],
  },
  reducers: {
    prepareDataDB: (state, action) => {
      // First checking if the data to append is already in the list
      for (let i = 0; i < state.dbData.length; i++) {
        if (state.dbData[i][action.payload.nameDb] !== undefined) {
          state.dbData[i]["sessionId"] = action.payload.sessionId;
          state.dbData[i]["timestamp"] = action.payload.timestamp;
          state.dbData[i][action.payload.nameDb] = action.payload.content;

          return state;
        }
      }

      // If it's not in the list, we append the list
      let dbData = {
        sessionId: action.payload.sessionId,
        timestamp: action.payload.timestamp,
      };

      dbData[action.payload.nameDb] = action.payload.content;

      state.dbData.push(dbData);
      return state;
    },
    sendDataDb: (state) => {
      const defaultLength = state.dbData.length;
      for (let i = 0; i < defaultLength; i++) {
        let myData = state.dbData.shift();
        fetch(backendUrl + "simulations/emprunteur_ibani/update/index.php", {
          method: "POST",
          body: JSON.stringify(myData),
          credentials: "include",
          crossDomain: true,
        });
      }
    },
    updateMontantEmprunt: (state, action) => {
      state.montantEmprunt = action.payload;
    },
    updateDureeEmprunt: (state, action) => {
      state.dureeEmprunt = action.payload;
    },
    updateQuotite: (state, action) => {
      state.quotite = action.payload;
    },
    updateFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    updateLastName: (state, action) => {
      state.lastName = action.payload;
    },
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updatePhone: (state, action) => {
      state.phone = action.payload;
    },
    updateDayBorn: (state, action) => {
      state.dayBorn = action.payload;
    },
    updateMonthBorn: (state, action) => {
      state.monthBorn = action.payload;
    },
    updateYearBorn: (state, action) => {
      state.yearBorn = action.payload;
    },
    updateTypePret: (state, action) => {
      state.typePret = action.payload;
    },
    updateCouvertures: (state, action) => {
      state.couvertures = action.payload;
    },
    updateStatutProfessionnel: (state, action) => {
      state.statutProfessionnel = action.payload;
    },
    updateFumeur: (state, action) => {
      state.fumeur = action.payload;
    },
    updateOptin: (state, action) => {
      state.optin = action.payload;
    },
  },
});

const backendSlice = createSlice({
  name: "backend",
  initialState: {
    startedSimulation: "false",
    phoneChecked: "false",
    sessionId: "",
    timestamp: "",
  },
  reducers: {
    updateStartedSimulation: (state, action) => {
      state.startedSimulation = action.payload;
    },
    updatePhoneChecked: (state, action) => {
      state.phoneChecked = action.payload;
    },
    updateSessionId: (state, action) => {
      state.sessionId = action.payload;
    },
    updateTimestamp: (state, action) => {
      state.timestamp = action.payload;
    },
  },
});

export const {
  updateMontantEmprunt,
  updateDureeEmprunt,
  updateQuotite,
  updateFirstName,
  updateLastName,
  updateEmail,
  updatePhone,
  updateDayBorn,
  updateMonthBorn,
  updateYearBorn,
  updateTypePret,
  updateCouvertures,
  updateFumeur,
  updateStatutProfessionnel,
  updateOptin,
  prepareDataDB,
  sendDataDb,
} = userInputSlice.actions;

export const {
  nextPage,
  previousPage,
  updateNextArrowState,
  putTempPageIndex,
  updateTempPageIndex,
  updateActualPageIndex,
} = navigationSlice.actions;

export const {
  updateStartedSimulation,
  updatePhoneChecked,
  updateSessionId,
  updateTimestamp,
} = backendSlice.actions;

export const store = configureStore({
  reducer: {
    navigation: navigationSlice.reducer,
    userInput: userInputSlice.reducer,
    backend: backendSlice.reducer,
  },
});
