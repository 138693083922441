/* eslint-disable react/prop-types */
import React from "react";
import Header from "../Components/Simulation/Header";
import Navbar from "../Components/Simulation/Navbar";
import Page from "../Components/Simulation/Page";
import { checkEmail } from "../Config/utils";
import { updateEmail } from "../Store/store";

export default function Email(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Quelle est votre adresse e-mail ?"
        subtitle="Pour garder contact"
        pageType="input"
        type="email"
        placeholder="Ex: contact@lexem.io"
        reduxAction={updateEmail}
        reduxStateName="email"
        checkData={checkEmail}
        dataType="string"
        myNameDb="email"
        optin
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
