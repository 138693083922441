/* eslint-disable react/prop-types */
import React from "react";
import "./PicklistElement.scss";

export default function PicklistElement(props) {
  return (
    <button
      className={"picklist-element " + props.letter}
      onClick={props.onClick}
    >
      <div className="letter">{props.letter}</div>
      <p className="text">{props.text}</p>
    </button>
  );
}
