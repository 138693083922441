/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateFumeur } from "../Store/store";

export default function Fumeur(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Êtes-vous fumeur ?"
        subtitle="Pour affiner notre calcul"
        pageType="picklist"
        values={["Oui", "Non"]}
        reduxStateName="fumeur"
        reduxAction={updateFumeur}
        myNameDb="fumeur"
        pageIndex={props.pageIndex}
      />
    </div>
  );
}
