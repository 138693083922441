/* eslint-disable react/prop-types */
import React from "react";
import PicklistElement from "./PicklistElement";
import { alphabet } from "../../../../Config/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  nextPage,
  prepareDataDB,
  sendDataDb,
  updateNextArrowState,
} from "../../../../Store/store";

export default function Picklist(props) {
  let picklistElements = [];
  const dispatch = useDispatch();
  let selectedValue = useSelector(
    (state) => state.userInput[props.reduxStateName]
  );
  let mySessionId = useSelector((state) => state.backend.sessionId);
  let myTimestamp = useSelector((state) => state.backend.timestamp);

  function handleClick(letter, text, mySessionId, myTimestamp, myNameDb) {
    document
      .querySelectorAll(".picklist-element.selected")
      .forEach((element) => {
        element.classList.remove("selected");
      });

    document
      .querySelector(".picklist-element." + letter)
      .classList.add("selected");
    dispatch(props.reduxAction(text));
    dispatch(updateNextArrowState("activate"));
    dispatch(
      prepareDataDB({
        sessionId: mySessionId,
        timestamp: myTimestamp,
        nameDb: myNameDb,
        content: text,
      })
    );

    setTimeout(() => {
      dispatch(sendDataDb());
      dispatch(nextPage({ sessionId: mySessionId, timestamp: myTimestamp }));
    }, 500);
  }

  for (let i = 0; i < props.values.length; i++) {
    picklistElements.push(
      <PicklistElement
        key={i}
        onClick={() => {
          handleClick(
            alphabet[i],
            props.values[i],
            mySessionId,
            myTimestamp,
            props.myNameDb
          );
        }}
        letter={alphabet[i]}
        text={props.values[i]}
      />
    );
  }

  function putActualValueSelected() {
    if (selectedValue !== "") {
      for (let i = 0; i < props.values.length; i++) {
        if (props.values[i] === selectedValue) {
          setTimeout(() => {
            if (
              document.querySelector(".picklist-element." + alphabet[i]) !==
              null
            ) {
              document
                .querySelector(".picklist-element." + alphabet[i])
                .classList.add("selected");
              dispatch(updateNextArrowState("activate"));
            }
          }, 100);
        }
      }
    }
  }

  putActualValueSelected();

  return <div className="picklist-container">{picklistElements}</div>;
}
