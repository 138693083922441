/* eslint-disable react/prop-types */
import React from "react";
import Header from ".././Components/Simulation/Header";
import Navbar from ".././Components/Simulation/Navbar";
import Page from ".././Components/Simulation/Page";
import { updateCouvertures } from "../Store/store";

export default function Couvertures(props) {
  return (
    <div className="page-container">
      <Header pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Navbar pageIndex={props.pageIndex} totalPages={props.totalPages} />
      <Page
        title="Quels sont vos couvertures ?"
        subtitle="Quelles sont les garanties exigées par votre banque dans le crédit"
        pageType="picklist"
        values={["Décès", "Décès - PTIA", "Décès - PTIA - ITT - IPT - IPP"]}
        reduxStateName="couvertures"
        pageIndex={props.pageIndex}
        reduxAction={updateCouvertures}
        myNameDb="couvertures"
      />
    </div>
  );
}
